<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Редактирование</div>
                <div class="page__desc">Измените данные склада</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="store !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="address">Адрес</label>
                <div class="control-group">
                    <input
                            v-model="store.address"
                            id="address"
                            type="text"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="lat">Координаты</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="store.lat"
                                    id="lat"
                                    class="form-control"
                                    required>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="store.lng"
                                    id="lng"
                                    class="form-control"
                                    required>
                        </div>
                    </div>
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreStoresUpdate',
        computed: {
            store() {
                return this.$store.state.stores.store;
            }
        },
        methods: {
            ...mapActions([
                'storeStoresGet',
                'storeStoresUpdate',
            ]),
            async getStore() {
                await this.storeStoresGet({
                    id: this.$route.params.id,
                })
            },
            async update() {
                await this.storeStoresUpdate({
                    id: this.store.id,
                    address: this.store.address,
                    lat: this.store.lat,
                    lng: this.store.lng
                })
            }
        },
        created() {
            this.getStore()
        },
    }
</script>